import React from 'react'
import { withRouter } from 'react-router-dom'
import { translate as _, formatDate } from '@morawadigital/skynet-framework'
import { Badge, Col, Dropdown, OverlayTrigger, Placeholder, Row, Tooltip } from 'react-bootstrap'
import Item from './Item'
import { getPlayerLicenseStatus } from '../../util'

class PlayerLicense extends React.Component {

    renderAdditionals( additionals, label ) {

        return (

            additionals && additionals.length > 0 &&

                <Row className='border-top mt-2 pt-2 bg-gray200'>

                    <Col>

                        <div className='fw-bold'>{ label }</div>

                        { additionals.map( ( e, i ) => <Row key={ i }>

                            <Col>{ e.Restriction.Title }</Col>

                            <Col xs='auto'>

                                <Badge>{ formatDate( e.From ) + '-' + formatDate( e.To ) }</Badge>

                            </Col>

                        </Row> ) }

                    </Col>

                </Row>

        )

    }

    renderFooter() {

        return (

            <>

                { this.renderAdditionals( this.props.item.SpecialAuthorities, _( 'Sonderberechtigungen' ) ) }

                { this.renderAdditionals( this.props.item.Restrictions,       _( 'Einschränkungen'      ) ) }

            </>

        )

    }

    renderName( item ) {

        return (

            <Row>

                <Col>{ item.PlayerLicenseType && item.PlayerLicenseType.Name }</Col>

                <Col xs='auto'>{ item.AgeGroup && <OverlayTrigger overlay={ <Tooltip>{ item.AgeGroup.Name }</Tooltip> }><span>{ item.AgeGroup.Shortname }</span></OverlayTrigger> }</Col>

            </Row>

        )

    }

    renderOptions() {

        return (

            this.props.editable &&

                <>

                    <Dropdown.Item as='button' onClick={ () => this.props.onEdit(     this.props.item ) }>{ _( 'Bearbeiten' ) }</Dropdown.Item>
                    <Dropdown.Item as='button' onClick={ () => this.props.onWithdraw( this.props.item ) }>{ _( 'Aufheben'   ) }</Dropdown.Item>
                    <Dropdown.Item as='button' onClick={ () => this.props.onDelete(   this.props.item ) }>{ _( 'Löschen'    ) }</Dropdown.Item>

                </>

        )

    }

    render() {

        const item   = this.props.item
        const status = item && getPlayerLicenseStatus( item.Status )

        return (

            <Item
                colClassName='border-bottom pb-3'
                colProps=    { { xs: 12 }                             }
                footer=      { this.props.item && this.renderFooter() }
                index=       { this.props.index                       }
                item=        { item                                   }
                name=        { item && this.renderName( item )        }
                options=     { this.renderOptions()                   }
            >

                <Col xs='6' sm='4' className='py-2'>

                    { item ?

                        status && status.label

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

                <Col xs='6' sm='4' className='py-2 text-end text-sm-start'>

                    { item ?

                        item.Club && <OverlayTrigger overlay={ <Tooltip>{ item.Club.Name }</Tooltip> }><span>{ item.Club.Shortname }</span></OverlayTrigger>

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

                <Col xs='12' sm='4' className='py-2 text-center text-sm-end'>

                    { item ?

                        <Badge>{ formatDate( item.FromDate ) + '-' + formatDate( item.ToDate ) }</Badge>

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

            </Item>

        )

    }

}

export default withRouter( PlayerLicense )