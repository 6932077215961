import { getLanguage, parseJSON } from '@morawadigital/skynet-framework'
import { datatron } from '../util/datatron'
import { fieldDefinition } from '../objectStores/fieldDefinition'
import { AgeGroup } from './AgeGroup'
import { Club } from './Club'
import { Coach } from './Coach'
import { CoachLicense } from './CoachLicense'
import { CoachTraining } from './CoachTraining'
import { Federation } from './Federation'
import { Nation } from './Nation'
import { Person } from './Person'
import { PersonRequest } from './PersonRequest'
import { Player } from './Player'
import { PlayerLicense } from './PlayerLicense'
import { PlayerLicenseRequest } from './PlayerLicenseRequest'
import { TransferCard } from './TransferCard'
import { InitialTransferCardRequest } from './InitialTransferCardRequest'
import { PersonNameChangeRequest } from './PersonNameChangeRequest'

const fieldDefinitions = {

    InitialTransfercardRequest: InitialTransferCardRequest,
    NameChangeRequest:          PersonNameChangeRequest,

    AgeGroup,
    Club,
    Coach,
    CoachLicense,
    CoachTraining,
    Federation,
    Nation,
    Person,
    PersonRequest,
    Player,
    PlayerLicense,
    PlayerLicenseRequest,
    TransferCard,

}

let requests = []

export const getFieldDefinition = options => {

    requests.push( options )

    requests.length === 1 && datatron.get( {

        cacheFirst:     true,
        objectStore:    fieldDefinition,
        requestOptions: { options: { method: 'GET' }, url: 'api/FieldDefinition/Get' },

        success: fields => {

            requests.forEach( request => {

                const filteredFields  = []
                const overwriteFields = {}

                if ( request.overwrite ) {

                    request.overwrite.forEach( e => {

                        e = fieldDefinition.convert( e )

                        overwriteFields[ e.property ] = e

                    } )

                }

                fields.data.forEach( field => {

                    if ( field.object === request.objectName ) {

                        if ( field.property in overwriteFields ) {

                            field = { ...field, ...overwriteFields[ field.property ] }

                        }

                        if ( field.isVisible ) {

                            if ( ( field.object in fieldDefinitions ) && field.property in fieldDefinitions[ field.object ] ) {

                                field = { ...field, ...fieldDefinitions[ field.object ][ field.property ] }

                            }

                            field.configuration = parseJSON( field.configuration, {} )

                            filteredFields.push( field )

                        }

                    }

                } )

                request.success( filteredFields )

            } )

            requests = []

        },

    } )

}

export const getLabel = field => {

    if ( ! field.label ) {

        return field.__label || field.property || field.name

    }

    try {

        const label = JSON.parse( field.label )

        if ( label ) {

            const lang = getLanguage()

            if ( lang in label ) {

                return label[ lang ]

            } else if ( lang !== 'en' && ( 'en' in label ) ) {

                return label[ 'en' ]

            } else {

                const keys = Object.keys( label )

                if ( keys.length ) {

                    return label[ keys[ 0 ] ]

                }

            }

        }

    } catch( e ) {}

    return field.label

}