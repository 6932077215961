import { Icon, translate as _, createDate, formatDateTime } from '@morawadigital/skynet-framework'
import { GENDER_FEMALE, GENDER_MALE, INITIAL_TRANSFER_CARD_REQUEST_STATUS_ACCEPTED, INITIAL_TRANSFER_CARD_REQUEST_STATUS_AT_IIHF, INITIAL_TRANSFER_CARD_REQUEST_STATUS_PENDING, INITIAL_TRANSFER_CARD_REQUEST_STATUS_REJECTED, PERSON_NAME_CHANGE_REQUEST_STATUS_ACCEPTED, PERSON_NAME_CHANGE_REQUEST_STATUS_PENDING, PERSON_NAME_CHANGE_REQUEST_STATUS_REJECTED, PERSON_REQUEST_STATUS_ACCEPTED, PERSON_REQUEST_STATUS_PENDING, PERSON_REQUEST_STATUS_REJECTED, PLAYER_LICENSE_REQUEST_STATUS_ACCEPTED, PLAYER_LICENSE_REQUEST_STATUS_PENDING, PLAYER_LICENSE_REQUEST_STATUS_REJECTED, PLAYER_LICENSE_STATUS_DELETED, PLAYER_LICENSE_STATUS_EXPIRED, PLAYER_LICENSE_STATUS_VALID, PLAYER_LICENSE_STATUS_WITHDRAWN, SIDE_LEFT, SIDE_RIGHT, SPECIAL_AUTHORITY_REQUEST_STATUS_ACCEPTED, SPECIAL_AUTHORITY_REQUEST_STATUS_PENDING, SPECIAL_AUTHORITY_REQUEST_STATUS_REJECTED, TRANSFER_REQUEST_STATUS_ACCEPTED, TRANSFER_REQUEST_STATUS_CANCELED, TRANSFER_REQUEST_STATUS_PENDING, TRANSFER_REQUEST_STATUS_REJECTED } from './constants'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const activeOptions = [

    { label: _( 'Aktiv'   ), value: true  },
    { label: _( 'Inaktiv' ), value: false },

]

const genders = [

    { value: GENDER_MALE,   label: 'Männlich' },
    { value: GENDER_FEMALE, label: 'Weiblich' },

]

const initialTransferCardRequestStatuses = [

    { value: INITIAL_TRANSFER_CARD_REQUEST_STATUS_PENDING,  label: 'Offen'      },
    { value: INITIAL_TRANSFER_CARD_REQUEST_STATUS_ACCEPTED, label: 'Akzeptiert' },
    { value: INITIAL_TRANSFER_CARD_REQUEST_STATUS_REJECTED, label: 'Abgelehnt'  },
    { value: INITIAL_TRANSFER_CARD_REQUEST_STATUS_AT_IIHF,  label: 'Bei IIHF'   },

]

const months = [

    { value:  1, label: 'Jänner'    },
    { value:  2, label: 'Februar'   },
    { value:  3, label: 'März'      },
    { value:  4, label: 'April'     },
    { value:  5, label: 'Mai'       },
    { value:  6, label: 'Juni'      },
    { value:  7, label: 'Juli'      },
    { value:  8, label: 'August'    },
    { value:  9, label: 'September' },
    { value: 10, label: 'Oktober'   },
    { value: 11, label: 'November'  },
    { value: 12, label: 'Dezember'  },

]

const personNameChangeRequestStatuses = [

    { value: PERSON_NAME_CHANGE_REQUEST_STATUS_PENDING,  label: 'Offen'      },
    { value: PERSON_NAME_CHANGE_REQUEST_STATUS_ACCEPTED, label: 'Akzeptiert' },
    { value: PERSON_NAME_CHANGE_REQUEST_STATUS_REJECTED, label: 'Abgelehnt'  },

]

const personRequestStatuses = [

    { value: PERSON_REQUEST_STATUS_PENDING,  label: 'Offen'      },
    { value: PERSON_REQUEST_STATUS_ACCEPTED, label: 'Akzeptiert' },
    { value: PERSON_REQUEST_STATUS_REJECTED, label: 'Abgelehnt'  },

]

const playerLicenseRequestStatuses = [

    { value: PLAYER_LICENSE_REQUEST_STATUS_PENDING,  label: 'Offen'      },
    { value: PLAYER_LICENSE_REQUEST_STATUS_ACCEPTED, label: 'Akzeptiert' },
    { value: PLAYER_LICENSE_REQUEST_STATUS_REJECTED, label: 'Abgelehnt'  },

]

const playerLicenseStatuses = [

    { value: PLAYER_LICENSE_STATUS_VALID,     label: 'Gültig'     },
    { value: PLAYER_LICENSE_STATUS_DELETED,   label: 'Gelöscht'   },
    { value: PLAYER_LICENSE_STATUS_WITHDRAWN, label: 'Aufgehoben' },
    { value: PLAYER_LICENSE_STATUS_EXPIRED,   label: 'Abgelaufen' },

]

const sides = [

    { value: SIDE_LEFT,  label: 'Links'  },
    { value: SIDE_RIGHT, label: 'Rechts' },

]

const specialAuthorityRequestStatuses = [

    { value: SPECIAL_AUTHORITY_REQUEST_STATUS_PENDING,  label: 'Offen'      },
    { value: SPECIAL_AUTHORITY_REQUEST_STATUS_ACCEPTED, label: 'Akzeptiert' },
    { value: SPECIAL_AUTHORITY_REQUEST_STATUS_REJECTED, label: 'Abgelehnt'  },

]

const transferRequestStatuses = [

    { value: TRANSFER_REQUEST_STATUS_PENDING,  label: 'Offen'      },
    { value: TRANSFER_REQUEST_STATUS_ACCEPTED, label: 'Akzeptiert' },
    { value: TRANSFER_REQUEST_STATUS_REJECTED, label: 'Abgelehnt'  },
    { value: TRANSFER_REQUEST_STATUS_CANCELED, label: 'Storniert'  },

]

export const createOption = e => ( { value: e, label: e } )

export const createOptions = ( options = [], valueKey = 'Id', labelKey = 'Name' ) => options.map( option => ( { label: option[ labelKey ], value: option[ valueKey ] } ) )

export const deleteItem = ( items, item ) => {

    items       = [ ...( items || [] ) ]
    const index = items.findIndex( e => e.Id === item.Id )

    if ( index !== -1 ) {

        items.splice( index, 1 )

    }

    return items

}

export const findTextInObject = ( obj, searchString, keys ) => {

    if ( ! keys ) {

        keys = Object.keys( obj )

    }

    searchString = searchString.toLowerCase()

    for ( let i = 0; i < keys.length; i++ ) {

        const key   = keys[ i   ]
        const value = obj[  key ]

        if ( typeof value === 'string' && value.toLowerCase().indexOf( searchString ) !== -1 ) {

            return true

        }

    }

    return false

}

export const getActiveOptions = () => activeOptions

export const getBooleanValue = e => e === null ? false : e

export const getDateValue = e => e === null ? '' : e

export const getFullName = ( e, qm ) => e ? ( e.lastName || e.LastName || e.firstName || e.FirstName ? ( ( e.lastName || e.LastName || '' ) + ' ' + ( e.firstName || e.FirstName || '' ) ) : ( qm ? '???' : '' ) ) : ( qm ? '???' : '' )

export const getGenders = () => genders.map( e => ( { ...e, label: _( e.label ) } ) )

export const getInitialTransferCardRequestStatuses = () => initialTransferCardRequestStatuses.map( e => ( { ...e, label: _( e.label ) } ) )

export const getInitialTransferCardRequestStatus = initialTransferCardRequestStatusId => {

    const initialTransferCardRequestStatus = initialTransferCardRequestStatuses.find( e => e.value === initialTransferCardRequestStatusId )

    return initialTransferCardRequestStatus && { ...initialTransferCardRequestStatus, label: _( initialTransferCardRequestStatus.label ) }

}

export const getMonths = () => months.map( e => ( { ...e, label: _( e.label ) } ) )

export const getNumberValue = e => e === null ? '' : e

export const getPersonNameChangeRequestStatus = personNameChangeRequestStatusId => {

    const personNameChangeRequestStatus = personNameChangeRequestStatuses.find( e => e.value === personNameChangeRequestStatusId )

    return personNameChangeRequestStatus && { ...personNameChangeRequestStatus, label: _( personNameChangeRequestStatus.label ) }

}

export const getPersonRequestStatus = personRequestStatusId => {

    const personRequestStatus = personRequestStatuses.find( e => e.value === personRequestStatusId )

    return personRequestStatus && { ...personRequestStatus, label: _( personRequestStatus.label ) }

}

export const getPersonNameChangeRequestStatuses = () => personNameChangeRequestStatuses.map( e => ( { ...e, label: _( e.label ) } ) )

export const getPersonRequestStatuses = () => personRequestStatuses.map( e => ( { ...e, label: _( e.label ) } ) )

export const getPlayerLicenseRequestStatus = playerLicenseRequestStatusId => {

    const playerLicenseRequestStatus = playerLicenseRequestStatuses.find( e => e.value === playerLicenseRequestStatusId )

    return playerLicenseRequestStatus && { ...playerLicenseRequestStatus, label: _( playerLicenseRequestStatus.label ) }

}

export const getPlayerLicenseRequestStatuses = () => playerLicenseRequestStatuses.map( e => ( { ...e, label: _( e.label ) } ) )

export const getPlayerLicenseStatus = playerLicenseStatusId => {

    const playerLicenseStatus = playerLicenseStatuses.find( e => e.value === playerLicenseStatusId )

    return playerLicenseStatus && { ...playerLicenseStatus, label: _( playerLicenseStatus.label ) }

}

export const getPlayerLicenseStatuses = () => playerLicenseStatuses.map( e => ( { ...e, label: _( e.label ) } ) )

export const getSelectValue = e => e ? e.value : ''

export const getSides = () => sides.map( e => ( { ...e, label: _( e.label ) } ) )

export const getSpecialAuthorityRequestStatuses = () => specialAuthorityRequestStatuses.map( e => ( { ...e, label: _( e.label ) } ) )

export const getSpecialAuthorityRequestStatus = specialAuthorityRequestStatusId => {

    const specialAuthorityRequestStatus = specialAuthorityRequestStatuses.find( e => e.value === specialAuthorityRequestStatusId )

    return specialAuthorityRequestStatus && { ...specialAuthorityRequestStatus, label: _( specialAuthorityRequestStatus.label ) }

}

export const getStatusLabel = ( label, icon, text, solvedBy, solvedDate ) => {

    if ( solvedBy || solvedDate ) {

        return (

            <OverlayTrigger overlay={

                <Tooltip>

                    { solvedBy && getFullName( solvedBy ) }

                    { solvedBy && solvedDate && <br /> }

                    { solvedDate && formatDateTime( solvedDate ) }

                </Tooltip>

            }>

                <span className={ 'text-' + text }><Icon icon={ icon } /> { label }</span>

            </OverlayTrigger>

        )

    }

    return (

        <span className={ 'text-' + text }><Icon icon={ icon } /> { label }</span>

    )

}

export const getStringValue = e => e === null ? '' : e

export const getTransferRequestStatus = transferRequestStatusId => {

    const transferRequestStatus = transferRequestStatuses.find( e => e.value === transferRequestStatusId )

    return transferRequestStatus && { ...transferRequestStatus, label: _( transferRequestStatus.label ) }

}

export const getTransferRequestStatuses = () => transferRequestStatuses.map( e => ( { ...e, label: _( e.label ) } ) )

export const isDevelopment = () => process.env.NODE_ENV === 'development'

export const isEmpty = e => e === undefined || e === null

export const isFemale = e => e && e.Gender && e.Gender.value === GENDER_FEMALE

export const olderThan = ( birthdate, years ) => {

    if ( ! birthdate ) {

        return false

    }

    const date = createDate( birthdate )

    date.setFullYear( date.getFullYear() + years )

    return date < createDate()

}

export const splitDate = date => {

    const parts = typeof date === 'string' ? date.split( '-' ) : []
    let year    = Number( parts[ 0 ] )
    let month   = Number( parts[ 1 ] )
    let day     = Number( parts[ 2 ] )

    if ( isNaN( year  ) || year  === 0 ) { year  = '' }
    if ( isNaN( month ) || month === 0 ) { month = '' }
    if ( isNaN( day   ) || day   === 0 ) { day   = '' }

    return { year, month, day }

}

export const splitRange = range => {

    const parts = typeof range === 'string' ? range.split( '-' ) : []
    let from    = Number( parts[ 0 ] )
    let to      = Number( parts[ 1 ] )

    if ( isNaN( from ) || from === 0 ) { from = '' }
    if ( isNaN( to   ) || to   === 0 ) { to   = '' }

    return { from, to }

}

export const updateItem = ( items, item ) => {

    items       = [ ...items || [] ]
    const index = items.findIndex( e => e.Id === item.Id )

    if ( index === -1 ) {

        items.push( item )

    } else {

        items[ index ] = item

    }

    return items

}

export const youngerThan = ( birthdate, years ) => {

    if ( ! birthdate ) {

        return false

    }

    const date = createDate( birthdate )

    date.setFullYear( date.getFullYear() + years )

    return date > createDate()

}