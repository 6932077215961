import { translate as _, formatDateTime } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_TEXTAREA, FIELD_TYPE_TEXTFIELD } from '../util/constants'
import { getFullName, getPersonNameChangeRequestStatus } from '../util'

const displayClub = e => e.value && e.value.Name

const displayDate = e => e.value && formatDateTime( e.value )

const displayName = e => e.value && getFullName( e.value )

const displayStatus = e => getPersonNameChangeRequestStatus( e.value ) && getPersonNameChangeRequestStatus( e.value ).label

export const PersonNameChangeRequest = {

    'Club':         { __label: _( 'Verein'                    ), __displayFn: displayClub     },
    'NewLastName':  { __label: _( 'Neuer Nachname'            ), __type: FIELD_TYPE_TEXTFIELD },
    'Note':         { __label: _( 'Kommentar'                 ), __type: FIELD_TYPE_TEXTAREA  },
    'RequestBy':    { __label: _( 'Antragsteller'             ), __displayFn: displayName     },
    'RequestDate':  { __label: _( 'Beantragt'                 ), __displayFn: displayDate     },
    'ResponseNote': { __label: _( 'Kommentar des Bearbeiters' ), __type: FIELD_TYPE_TEXTAREA  },
    'SolvedBy':     { __label: _( 'Bearbeiter'                ), __displayFn: displayName     },
    'SolvedDate':   { __label: _( 'Bearbeitet'                ), __displayFn: displayDate     },
    'Status':       { __label: _( 'Status'                    ), __displayFn: displayStatus   },

}