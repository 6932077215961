import { ObjectStore } from '@morawadigital/skynet-framework'

export const fieldDefinition = new ObjectStore( {

    name:   'FieldDefinition',
    version: 2,
    indexes: [

        { name: 'id',            convertion: { key: 'Id'         } },
        { name: 'isRequired',    convertion: { key: 'IsRequired' } },
        { name: 'isVisible',     convertion: { key: 'IsVisible'  } },
        { name: 'label',         convertion: { key: 'Label'      } },
        { name: 'object',        convertion: { key: 'Object'     } },
        { name: 'order',         convertion: { key: 'Order'      } },
        { name: 'property',      convertion: { key: 'Property'   } },
        { name: 'configuration', convertion: { key: 'Config'     } },

    ],

} )