import React from 'react'
import { ajxs, translate as _, Icon, ConfirmationModal, ajx } from '@morawadigital/skynet-framework'
import { createOptions } from '../../util'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import Field from '../controls/Field'
import { FIELD_TYPE_AUTOCOMPLETE } from '../../util/constants'

export default class ClubMappings extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            clubs:           null,
            mappings:        null,
            mappingToDelete: null,
            selectedClub:    null,
            working:         false,

        }

    }

    componentDidMount() {

        this.load()

    }

    deleteMapping() {

        this.setState( { working: true }, () => ajx( {

            complete: () => this.setState( { mappingToDelete: null, working: false } ),
            data:     { id: this.state.mappingToDelete.Id },
            success:  () => this.setState( { mappings: this.state.mappings.filter( e => e.Id !== this.state.mappingToDelete.Id ) } ),
            url:      'api/MappedClub/Delete',

        } ) )

    }

    getMappedClubName( mapping ) {

        return ( mapping && ( mapping.Club1Id === this.props.clubId ? mapping.Club2Name : mapping.Club1Name ) )

    }

    load() {

        ajxs( { toggleLoading: true }, [

            { options: { method: 'GET' }, success: e => this.setState( { clubs:    createOptions( e ) } ), url: 'api/Club/Get'                                            },
            { options: { method: 'GET' }, success: e => this.setState( { mappings:                e   } ), url: 'api/MappedClub/Get', data: { clubId: this.props.clubId } },

        ] )

    }

    save( e ) {

        e.preventDefault()

        this.setState( { working: true }, () => ajx( {

            complete: () => this.setState( { working: false } ),
            data:     { club1Id: this.props.clubId, club2Id: this.state.selectedClub.value },
            single:   true,
            success:  e => this.setState( { selectedClub: null, mappings: [ ...this.state.mappings, e ] } ),
            url:      'api/MappedClub/AddOrEdit',

        } ) )

    }

    renderMapping( mapping, i ) {

        return (

            <Card className='mb-2' key={ i }>

                <Card.Body className='p-2'>

                    <Row className='align-items-center'>

                        <Col>{ this.getMappedClubName( mapping ) }</Col>

                        <Col xs='auto'>

                            <Button variant='outline-danger' size='xs' onClick={ () => this.setState( { mappingToDelete: mapping } ) } disabled={ this.state.working }><Icon icon='trash' /></Button>

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

    render() {

        return (

            <>

                <Row>

                    <Col xs='12' md='6' className='mb-3 order-md-1'>

                        <Card>

                            <Card.Header>{ _( 'Verknüpfung hinzufügen' ) }</Card.Header>

                            <Card.Body>

                                <Form onSubmit={ e => this.save( e ) }>

                                    <fieldset disabled={ this.state.working }>

                                        <Field
                                            disabled=    { this.state.working }
                                            field=       { { object: 'add-club-mapping', property: 'club', isRequired: true, __label: _( 'Verein' ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'clubs' } }
                                            onChange=    { ( _, selectedClub ) => this.setState( { selectedClub } ) }
                                            value=       { this.state.selectedClub }
                                            valueOptions={ { clubs: this.state.clubs && this.state.clubs.filter( e => ( ! this.state.mappings || ! this.state.mappings.find( f => e.value === f.Club1Id || e.value === f.Club2Id ) ) && e.value !== this.props.clubId ) } }
                                        />

                                    </fieldset>

                                    <Row>

                                        <Col xs={ { offset: 4, span: 8 } }>

                                            <Button variant='outline-primary' type='submit' disabled={ ! this.state.selectedClub }>{ _( 'Hinzufügen' ) }</Button>

                                        </Col>

                                    </Row>

                                </Form>

                            </Card.Body>

                        </Card>

                    </Col>

                    <Col xs='12' md='6'>

                        <Card>

                            <Card.Header>{ _( 'Bestehende Verknüpfungen' ) }</Card.Header>

                            <Card.Body>

                                { this.state.mappings && (

                                    this.state.mappings.length ?

                                        this.state.mappings.map( ( e, i ) => this.renderMapping( e, i ) )

                                    :

                                        <p className='text-center text-muted'>{ _( 'Dieser Verein ist mit keinem anderen Verein verknüpft.' ) }</p>

                                ) }

                            </Card.Body>

                        </Card>

                    </Col>

                </Row>

                <ConfirmationModal
                    onConfirm={ () => this.deleteMapping() }
                    onHide=   { () => this.setState( { mappingToDelete: null } ) }
                    show=     { this.state.mappingToDelete !== null }
                    body=     { <div className='lead text-center'>{ _( 'Möchten Sie diese Verknüpfung wirklich löschen?' ) }<div className='my-3 fw-bold'>{ this.getMappedClubName( this.state.mappingToDelete ) }</div></div> }
                    title=    { _( 'Verknüpfung löschen?' ) }
                />

            </>

        )

    }

}