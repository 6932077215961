import React from 'react'
import { Button, Col, Dropdown, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { Icon, ajx, translate as _, sortByKey } from '@morawadigital/skynet-framework'
import { createOptions, deleteItem, findTextInObject, getFullName, getInitialTransferCardRequestStatuses, getPersonNameChangeRequestStatuses, getPersonRequestStatuses, getPlayerLicenseRequestStatuses, getSpecialAuthorityRequestStatuses, getTransferRequestStatuses, updateItem } from '../../util'
import { REQUEST_TYPE_INITIAL_TRANSFER_CARD, REQUEST_TYPE_PERSON, REQUEST_TYPE_PERSON_NAME_CHANGE, REQUEST_TYPE_RENEWAL_LICENSE, REQUEST_TYPE_SPECIAL_AUTHORITY, REQUEST_TYPE_TRANSFER } from '../../util/constants'
import PlayerLicenseRequestItem from '../items/PlayerLicenseRequest'
import PersonRequestItem from '../items/PersonRequest'
import PersonNameChangeRequestItem from '../items/PersonNameChangeRequest'
import InitialTransferCardRequestItem from '../items/InitialTransferCardRequest'
import TransferRequestItem from '../items/TransferRequest'
import PersonRequestModal from '../modals/PersonRequest'
import PersonNameChangeRequestModal from '../modals/PersonNameChangeRequest'
import InitialTransferCardRequestModal from '../modals/InitialTransferCardRequest'
import TransferRequestModal from '../modals/TransferRequest'
import { isClub, isFederation } from '../../util/context'
import RenewalLicenseRequest from '../modals/RenewalLicenseRequest'
import SpecialAuthorityRequestItem from '../items/SpecialAuthorityRequest'
import SpecialAuthorityRequest from '../modals/SpecialAuthorityRequest'

export default class PlayerLicenseRequests extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            activeInitialTransferCardRequest:   null,
            activePersonNameChangeRequest:      null,
            activePersonRequest:                null,
            activeRequestablePlayerLicenseType: null,
            federations:                        null,
            items:                              null,
            renewalLicenseRequestOpen:          false,
            requestablePlayerLicenseTypes:      [],
            specialAuthorityRequestOpen:        false,
            transferRequestOpen:                false,

        }

    }

    componentDidMount() {

        this.loadItems()

        isFederation( this.props.context ) && this.loadFederations()

        this.loadRequestablePlayerLicenseTypes()

    }

    checkItem( item ) {

        if ( this.props.textFilter && ! findTextInObject( item, this.props.textFilter, [ 'clubName', 'personName' ] ) ) {

            return false

        }

        return true

    }

    convertItem( item ) {

        return {

            ...item,

            clubName:   item.Club   ? item.Club.Name                          : '',
            personName: item.Player ? getFullName( item.Player.Person, true ) : getFullName( item, true ),

        }

    }

    convertItems( items ) {

        return items.map( e => this.convertItem( e ) )

    }

    createInitialTransferCardRequest() {

        return {

            Birthdate:               null,
            BirthName:               '',
            DocumentsByPostReceived: false,
            DocumentsByPostSent:     false,
            FirstName:               '',
            Gender:                  null,
            isNew:                   true,
            LastName:                '',
            LeavingFederation:       null,
            MainFederation:          null,
            PersonDocuments:         [],

        }

    }

    createPersonNameChangeRequest() {

        return {

            DocumentsByPostReceived: false,
            DocumentsByPostSent:     false,
            isNew:                   true,
            NewLastName:             '',
            PersonDocuments:         [],

        }

    }

    createPersonRequest() {

        return {

            Birthdate:               null,
            BirthName:               '',
            DocumentsByPostReceived: false,
            DocumentsByPostSent:     false,
            FirstName:               '',
            Gender:                  null,
            isNew:                   true,
            LastName:                '',
            Note:                    '',
            PersonDocuments:         [],
            ResponseNote:            '',
            TargetLeagueType:        null,

        }

    }

    getItemComponent() {

        return (

            this.props.typeFilter === REQUEST_TYPE_PERSON ?

                PersonRequestItem

            : this.props.typeFilter === REQUEST_TYPE_PERSON_NAME_CHANGE ?

                PersonNameChangeRequestItem

            : this.props.typeFilter === REQUEST_TYPE_INITIAL_TRANSFER_CARD ?

                InitialTransferCardRequestItem

            : this.props.typeFilter === REQUEST_TYPE_SPECIAL_AUTHORITY ?

                SpecialAuthorityRequestItem

            : this.props.typeFilter === REQUEST_TYPE_RENEWAL_LICENSE ?

                PlayerLicenseRequestItem

            :

                TransferRequestItem

        )

    }

    getDataParams() {

        const params              = { statusId: this.props.statusFilter }
        const playerLicenseTypeId = this.getFilterPlayerLicenseTypeId()

        if ( this.props.context ) {

            if ( this.props.context.federation ) { params.fedId  = this.props.context.federation.id }
            if ( this.props.context.club       ) { params.clubId = this.props.context.club.id       }

        }

        if ( this.props.typeFilter === REQUEST_TYPE_RENEWAL_LICENSE ) {

            params.renewal = true

        } else if ( playerLicenseTypeId ) {

            params.playerLicenseTypeId = playerLicenseTypeId

        }

        return params

    }

    getDataUrl() {

        return (

            this.props.typeFilter === REQUEST_TYPE_PERSON ?

                'api/PersonRequest/Get'

            : this.props.typeFilter === REQUEST_TYPE_PERSON_NAME_CHANGE ?

                'api/NameChangeRequest/Get'

            : this.props.typeFilter === REQUEST_TYPE_INITIAL_TRANSFER_CARD ?

                'api/InitialTransfercardRequest/Get'

            : this.props.typeFilter === REQUEST_TYPE_SPECIAL_AUTHORITY ?

                'api/SpecialAuthorityRequest/Get'

            : this.props.typeFilter === REQUEST_TYPE_RENEWAL_LICENSE ?

                'api/PlayerLicenseRequest/Get'

            :

                'api/TransferRequest/Get'

        )

    }

    getItemProps() {

        const props = {

            onDelete:    e => this.setState( { items: deleteItem( this.state.items, e ) } ),
            onUpdate:    e => this.updateItem( this.props.typeFilter, e ),
            respondable: isFederation( this.props.context ),
            showPerson:  true,

        }

        if ( this.props.typeFilter === REQUEST_TYPE_PERSON ) {

            props.onClick = activePersonRequest => this.setState( { activePersonRequest } )

        } else if ( this.props.typeFilter === REQUEST_TYPE_PERSON_NAME_CHANGE ) {

            props.onClick = activePersonNameChangeRequest => this.setState( { activePersonNameChangeRequest } )

        } else if ( this.props.typeFilter === REQUEST_TYPE_INITIAL_TRANSFER_CARD ) {

            props.onClick = activeInitialTransferCardRequest => this.setState( { activeInitialTransferCardRequest } )

        }

        return props

    }

    getFilterPlayerLicenseTypeId() {

        return (

            this.props.typeFilter.indexOf( REQUEST_TYPE_TRANSFER ) === 0 ?

                Number( this.props.typeFilter.split( '-' )[ 1 ] )

            : null

        )

    }

    getStatusFilterOptions() {

        return (

            this.props.typeFilter === REQUEST_TYPE_PERSON ?

                getPersonRequestStatuses()

            : this.props.typeFilter === REQUEST_TYPE_INITIAL_TRANSFER_CARD ?

                getPersonNameChangeRequestStatuses()

            : this.props.typeFilter === REQUEST_TYPE_INITIAL_TRANSFER_CARD ?

                getInitialTransferCardRequestStatuses()

            : this.props.typeFilter === REQUEST_TYPE_SPECIAL_AUTHORITY ?

                getSpecialAuthorityRequestStatuses()

            : this.props.typeFilter === REQUEST_TYPE_RENEWAL_LICENSE ?

                getPlayerLicenseRequestStatuses()

            :

                getTransferRequestStatuses()

        )

    }

    handleStatusFilterChange( e ) {

        this.props.onUiValueChange( 'statusFilterRequests', Number( e.target.value ) )

        setTimeout( () => this.loadItems(), 0 )

    }

    handleTypeFilterChange( e ) {

        this.props.onUiValueChange( 'typeFilterRequests', e.target.value )

        setTimeout( () => this.loadItems(), 0 )

    }

    loadItems() {

        ajx( {

            data:          this.getDataParams(),
            options:       { method: 'GET' },
            success:       e => this.setState( { items: this.convertItems( e ) } ),
            timeout:       400,
            toggleLoading: true,
            url:           this.getDataUrl(),

        } )

    }

    loadFederations() {

        ajx( {

            options: { method: 'GET' },
            success: e => this.setState( { federations: createOptions( e ) } ),
            url:     'api/Federation/Get',

        } )

    }

    loadRequestablePlayerLicenseTypes() {

        const data = {}

        if ( isClub(       this.props.context ) ) { data.clubId = this.props.context.club.id       } else
        if ( isFederation( this.props.context ) ) { data.fedId  = this.props.context.federation.id }

        ajx( {

            options: { method: 'GET' },
            success: requestablePlayerLicenseTypes => this.setState( { requestablePlayerLicenseTypes } ),
            url:     'api/PlayerLicenseType/Get',

            data,

        } )

    }

    sort( items ) {

        if ( items ) {

            sortByKey( items, 'RequestDate' )

            this.props.sortDirection === 'desc' && items.reverse()

        }

        return items

    }

    toggleSortDirection() {

        this.props.onUiValueChange( 'sortDirectionRequests', this.props.sortDirection === 'asc' ? 'desc' : 'asc' )

    }

    updateItem( requestType, item, playerLicenseType ) {

        if ( requestType !== this.props.typeFilter || ( playerLicenseType && playerLicenseType.Id !== this.getFilterPlayerLicenseTypeId() ) ) {

            return

        }

        item.Status === this.props.statusFilter ?

            this.setState( { items: updateItem( this.state.items, this.convertItem( item ) ) } )

        :

            this.setState( { items: deleteItem( this.state.items, item ) } )

    }

    renderFilters() {

        return (

            <Form className='sticky-filters'>

                <fieldset disabled={ this.props.loading }>

                    <Row className='align-items-center gy-1'>

                        <Col xs='12' md='4'>

                            <Form.Select className='py-2' onChange={ e => this.handleTypeFilterChange( e ) } value={ this.props.typeFilter }>

                                <option value={ REQUEST_TYPE_PERSON                }>{ _( 'Lizenzerstausstellungen'                   ) }</option>
                                <option value={ REQUEST_TYPE_INITIAL_TRANSFER_CARD }>{ _( 'Lizenzerstausstellungen mit Transferkarte' ) }</option>
                                <option value={ REQUEST_TYPE_RENEWAL_LICENSE       }>{ _( 'Passverlängerungen'                        ) }</option>
                                <option value={ REQUEST_TYPE_TRANSFER              }>{ _( 'Vereinswechsel'                            ) }</option>
                                <option value={ REQUEST_TYPE_SPECIAL_AUTHORITY     }>{ _( 'Sonderberechtigungen'                      ) }</option>
                                <option value={ REQUEST_TYPE_PERSON_NAME_CHANGE    }>{ _( 'Namensänderungen'                          ) }</option>

                                { this.state.requestablePlayerLicenseTypes.map( ( e, i ) => <option value={ REQUEST_TYPE_TRANSFER + '-' + e.Id } key={ i }>{ e.Name }</option> ) }

                            </Form.Select>

                        </Col>

                        <Col xs='12' sm='4'>

                            <Form.Select className='py-2' onChange={ e => this.handleStatusFilterChange( e ) } value={ this.props.statusFilter }>

                                { this.getStatusFilterOptions().map( ( e, i ) =>

                                    <option value={ e.value } key={ i }>{ e.label }</option>

                                ) }

                            </Form.Select>

                        </Col>

                        <Col>

                            <Form.Control
                                onChange={ e => this.props.onUiValueChange( 'textFilterRequests', e.target.value ) }
                                placeholder={ _( 'Filtern...' ) }
                                value={ this.props.textFilter }
                            />

                        </Col>

                        <Col xs='auto'>

                            <OverlayTrigger overlay={ <Tooltip>{ _( 'Sortierung umkehren' ) }</Tooltip> }>

                                <Button onClick={ () => this.toggleSortDirection() } variant='none'><Icon icon={ this.props.sortDirection === 'asc' ? 'sort-amount-up-alt' : 'sort-amount-down' } /></Button>

                            </OverlayTrigger>

                        </Col>

                    </Row>

                </fieldset>

            </Form>

        )

    }

    renderItems() {

        const ItemComponent = this.getItemComponent()
        const filteredItems = this.sort( this.state.items ? this.state.items.filter( e => this.checkItem( e ) ) : null )

        return (

            this.props.loading ?

                <div className='my-5'>{ Array.from( { length: 3 } ).map( ( e, i ) => <ItemComponent key={ i } index={ i } /> ) }</div>

            : filteredItems && ! filteredItems.length ?

                <div className='my-3 text-center'>{ _( 'Keine Einträge gefunden.' ) }</div>

            : filteredItems ?

                <>

                    <div className='text-center text-muted mt-2 mb-3'>{ filteredItems.length + ' ' + ( filteredItems.length === 1 ? _( 'Eintrag' ) : _( 'Einträge' ) ) }</div>

                    { filteredItems.map( ( item, i ) => <ItemComponent key={ i } item={ item } { ...this.getItemProps() } federations={ this.state.federations } context={ this.props.context } /> ) }

                </>

            :

                ''

        )


    }

    renderSubheaderBlock() {

        return (

            isClub( this.props.context ) &&

                <Dropdown>

                    <Dropdown.Toggle>

                        { _( 'Neuer Antrag' ) }

                    </Dropdown.Toggle>

                    <Dropdown.Menu>

                        <Dropdown.Item as='button' onClick={ () => this.setState( { activePersonRequest: this.createPersonRequest() } ) }>{ _( 'Lizenzerstausstellung' ) }</Dropdown.Item>
                        <Dropdown.Item as='button' onClick={ () => this.setState( { activeInitialTransferCardRequest: this.createInitialTransferCardRequest() } ) }>{ _( 'Lizenzerstausstellung mit Transferkarte' ) }</Dropdown.Item>
                        <Dropdown.Item as='button' onClick={ () => this.setState( { renewalLicenseRequestOpen: true } ) }>{ _( 'Passverlängerung' ) }</Dropdown.Item>
                        <Dropdown.Item as='button' onClick={ () => this.setState( { activeRequestablePlayerLicenseType: null, transferRequestOpen: true } ) }>{ _( 'Vereinswechsel' ) }</Dropdown.Item>
                        <Dropdown.Item as='button' onClick={ () => this.setState( { specialAuthorityRequestOpen: true } ) }>{ _( 'Sonderberechtigung' ) }</Dropdown.Item>
                        <Dropdown.Item as='button' onClick={ () => this.setState( { activePersonNameChangeRequest: this.createPersonNameChangeRequest() } ) }>{ _( 'Namensänderung' ) }</Dropdown.Item>

                        <Dropdown.Divider />

                        { this.state.requestablePlayerLicenseTypes.map( ( e, i ) =>

                            <Dropdown.Item as='button' onClick={ () => this.setState( { activeRequestablePlayerLicenseType: e, transferRequestOpen: true } ) } key={ i }>{ e.Name }</Dropdown.Item>

                        ) }

                    </Dropdown.Menu>

                </Dropdown>

        )

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='id-card' className='subheader-icon' /> { _( 'Anträge' ) }

                    </h1>

                    <div className='subheader-block'>

                        { this.renderSubheaderBlock() }

                    </div>

                </div>

                { this.renderFilters() }

                { this.renderItems() }

                <PersonRequestModal
                    context=      { this.props.context }
                    onHide=       { () => this.setState( { activePersonRequest: null } ) }
                    onUpdate=     { e => this.updateItem( REQUEST_TYPE_PERSON, e ) }
                    personRequest={ this.state.activePersonRequest }
                    respondable=  { isFederation( this.props.context ) }
                />

                <PersonNameChangeRequestModal
                    context=                { this.props.context }
                    onHide=                 { () => this.setState( { activePersonNameChangeRequest: null } ) }
                    onUpdate=               { e => this.updateItem( REQUEST_TYPE_PERSON_NAME_CHANGE, e ) }
                    personNameChangeRequest={ this.state.activePersonNameChangeRequest }
                    respondable=            { isFederation( this.props.context ) }
                />

                <InitialTransferCardRequestModal
                    context=                   { this.props.context }
                    initialTransferCardRequest={ this.state.activeInitialTransferCardRequest }
                    onHide=                    { () => this.setState( { activeInitialTransferCardRequest: null } ) }
                    onUpdate=                  { e => this.updateItem( REQUEST_TYPE_INITIAL_TRANSFER_CARD, e ) }
                    respondable=               { isFederation( this.props.context ) }
                />

                <TransferRequestModal
                    context=          { this.props.context }
                    onHide=           { () => this.setState( { activeRequestablePlayerLicenseType: null, transferRequestOpen: false } ) }
                    onUpdate=         { e => this.updateItem( REQUEST_TYPE_TRANSFER, e, this.state.activeRequestablePlayerLicenseType ) }
                    playerLicenseType={ this.state.activeRequestablePlayerLicenseType }
                    show=             { this.state.transferRequestOpen }
                />

                <RenewalLicenseRequest
                    context= { this.props.context }
                    onHide=  { () => this.setState( { renewalLicenseRequestOpen: false } ) }
                    onUpdate={ e => this.updateItem( REQUEST_TYPE_RENEWAL_LICENSE, e ) }
                    show=    { this.state.renewalLicenseRequestOpen }
                />

                <SpecialAuthorityRequest
                    context= { this.props.context }
                    onHide=  { () => this.setState( { specialAuthorityRequestOpen: false } ) }
                    onUpdate={ e => this.updateItem( REQUEST_TYPE_SPECIAL_AUTHORITY, e ) }
                    show=    { this.state.specialAuthorityRequestOpen }
                />

            </>

        )

    }

}