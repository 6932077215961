import { translate as _ } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_ADDRESSES, FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_TEXTFIELD, FIELD_TYPE_TOGGLE } from '../util/constants'

export const Club = {

    'Active':         { __label: _( 'Aktiv'           ), __type: FIELD_TYPE_TOGGLE                                    },
    'Addresses':      { __label: _( 'Adressen'        ), __type: FIELD_TYPE_ADDRESSES                                 },
    'ClubNr':         { __label: _( 'Vereinsnummer'   ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'ClubType':       { __label: _( 'Typ'             ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'clubTypes'   },
    'Domicile':       { __label: _( 'Vereinssitz'     ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'Federation':     { __label: _( 'Verband'         ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'federations' },
    'HighestLeague':  { __label: _( 'Höchste Liga'    ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'leagues'     },
    'Homepage':       { __label: _( 'Webseite'        ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'Icon':           { __label: _( 'Icon'            ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'Name':           { __label: _( 'Name'            ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'Passname':       { __label: _( 'Passname'        ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'RegistryCourt':  { __label: _( 'Geschäftsstelle' ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'RegistryNumber': { __label: _( 'Registry Number' ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'Shortname':      { __label: _( 'Kurzbezeichnung' ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'TaxId':          { __label: _( 'UID'             ), __type: FIELD_TYPE_TEXTFIELD                                 },

}