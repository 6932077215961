import React from 'react'
import { Icon, translate as _, ajx, formatDate } from '@morawadigital/skynet-framework'
import { Alert, Button, Card, Col, Collapse, Form, Modal, Row } from 'react-bootstrap'
import { createOptions, getFullName, getSelectValue } from '../../util'
import Field from '../controls/Field'
import { FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_TEXTAREA } from '../../util/constants'
import { isClub } from '../../util/context'
import { sprintf } from 'sprintf-js'

export default class SpecialAuthorityRequest extends React.Component {

    constructor( props ) {

        super( props )

        this.state = this.getDefaultState()

    }

    componentDidMount() {

        this.loadSpecialAuthorities()

    }

    componentDidUpdate( prevProps ) {

        this.props.show !== prevProps.show && this.setState( {

            ...this.getDefaultState(),

            specialAuthorities: this.state.specialAuthorities,
            valueOptions:       this.state.valueOptions,

        } )

    }

    getDefaultState() {

        return {

            comment:             '',
            complete:            false,
            error:               false,
            loadingPlayers:      false,
            playerInformationId: null,
            players:             [],
            saving:              false,
            search:              { lastName: '', firstName: '', passNumber: '' },
            selectedPlayer:      null,
            specialAuthorities:  null,
            specialAuthorityId:  null,
            success:             false,
            valueOptions:        {},

        }

    }

    getStatusMessage( text, className, icon, spin ) {

        const iconProps = { icon }

        if ( spin ) {

            iconProps.spin = true

        }

        return (

            <div className={ 'text-center ' + className }>

                <div className='fs-1 my-3'>

                    <Icon { ...iconProps } />

                </div>

                <p className='lead'>{ text }</p>

            </div>

        )

    }

    loadPlayers( e ) {

        e.preventDefault()

        if ( ! this.searchFormSubmittable() ) {

            return

        }

        this.setState( { loadingPlayers: true }, () => ajx( {

            complete: () => this.setState( { loadingPlayers: false } ),
            options:  { method: 'GET' },
            success:  players => this.setState( { players } ),
            url:      'api/SpecialAuthorityRequest/GetPlayer',

            data:     {

                clubId:    ( isClub( this.props.context ) ? this.props.context.club.id : '' ),
                firstName: this.state.search.firstName,
                lastName:  this.state.search.lastName,
                passNr:    this.state.search.passNumber,

            },

        } ) )

    }

    loadSpecialAuthorities() {

        ajx( {

            options: { method: 'GET' },
            success: specialAuthorities => this.setState( { specialAuthorities, valueOptions: { ...this.state.valueOptions, specialAuthorities: createOptions( specialAuthorities, undefined, 'Title' ) } } ),
            url:     'api/SpecialAuthority/Get',

        } )

    }

    save() {

        this.setState( { saving: true }, () => ajx( {

            complete: () => this.setState( { saving: false, complete: true } ),
            error:    () => this.setState( { error: true } ),
            single:   true,
            success:  e => this.setState( { success: true }, () => this.props.onUpdate( e ) ),
            url:      'api/SpecialAuthorityRequest/Create',

            data: {

                clubId:              ( isClub( this.props.context ) ? this.props.context.club.id : '' ),
                note:                this.state.comment,
                playerId:            this.state.selectedPlayer.Id,
                playerLicenseTypeId: this.state.selectedPlayer.ActiveLicense && this.state.selectedPlayer.ActiveLicense.PlayerLicenseType && this.state.selectedPlayer.ActiveLicense.PlayerLicenseType.Id,
                specialAuthorityId:  getSelectValue( this.state.specialAuthorityId ),

            }

        } ) )

    }

    searchFormSubmittable() {

        return ( this.state.search.lastName || this.state.search.firstName || this.state.search.passNumber )

    }

    renderPlayer( player, i ) {

        const information     = []
        const addInformation  = e => information.push( e )
        const informationOpen = this.state.playerInformationId === player.Id

        if ( player.Informations ) {

            if ( player.Informations.OpenSpecialAuthorityRequests ) {

                player.Informations.OpenSpecialAuthorityRequests.forEach( e => addInformation( sprintf( _( 'Offener Sonderberechtigungsantrag vorhanden (%s).' ), e.Title ) ) )

            }

        }

        return (

            <Card
                className='mb-1 selectable'
                key={ i }
                onClick={ () => this.setState( { selectedPlayer: player } ) }
            >

                <Card.Body className='p-2'>

                    <Row className='gx-0'>

                        <Col xs='3' lg='3'>{ player.PassNr }</Col>

                        <Col>{ getFullName( player.Person, true ) }</Col>

                        <Col xs='auto'>{ player.Person && player.Person.Birthdate ? formatDate( player.Person.Birthdate ) : '' }</Col>

                        <Col xs='1' className='text-end'>

                            { information.length > 0 && <Icon icon='chevron-down' button fw className={ 'card-details-toggle' + ( informationOpen ? ' open' : '' ) } onClick={ e => e.stopPropagation() || this.setState( { playerInformationId: informationOpen ? null : player.Id } ) } /> }

                        </Col>

                    </Row>

                    <Collapse in={ informationOpen }>

                        <div>

                            <ul className='my-2'>

                                { information.map( ( e, i ) => <li key={ i }>{ e }</li> ) }

                            </ul>

                        </div>

                    </Collapse>

                </Card.Body>

            </Card>

        )

    }

    renderRequestForm() {

        const specialAuthorityId = getSelectValue( this.state.specialAuthorityId )
        const specialAuthority   = specialAuthorityId && this.state.specialAuthorities && this.state.specialAuthorities.find( e => e.Id === specialAuthorityId )
        const alreadyRequested   = specialAuthority && this.state.selectedPlayer.Informations && this.state.selectedPlayer.Informations.OpenSpecialAuthorityRequests && this.state.selectedPlayer.Informations.OpenSpecialAuthorityRequests.find( e => e.Identifier === specialAuthority.Identifier )

        return (

            <>

                <Field
                    field={ { object: 'special-authority-request', property: 'specialAuthorityId', __label: _( 'Sonderberechtigung' ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'specialAuthorities' } }
                    onChange={ ( _, specialAuthorityId ) => this.setState( { specialAuthorityId } ) }
                    value={ this.state.specialAuthorityId }
                    valueOptions={ this.state.valueOptions }
                />

                <Field
                    field={ { object: 'special-authority-request', property: 'comment', __label: _( 'Kommentar' ), __type: FIELD_TYPE_TEXTAREA } }
                    onChange={ ( _, comment ) => this.setState( { comment } ) }
                    value={ this.state.comment }
                />

                { alreadyRequested && <Alert className='mt-3' variant='warning'>

                    { _( 'Für diese Person existiert ein offener Antrag mit der ausgewählten Sonderberechtigung.' ) }

                </Alert> }

            </>

        )

    }

    renderSearchForm() {

        return (

            <>

                <Form onSubmit={ e => this.loadPlayers( e ) } className='mb-3'>

                    <fieldset disabled={ this.state.loadingPlayers }>

                        <Row className='g-1'>

                            <Col xs='6' sm='3'><Form.Control placeholder={ _( 'Nachname'   ) } value={ this.state.search.lastName   } onChange={ e => this.setState( { search: { ...this.state.search, lastName:   e.target.value } } ) } /></Col>

                            <Col xs='6' sm='3'><Form.Control placeholder={ _( 'Vorname'    ) } value={ this.state.search.firstName  } onChange={ e => this.setState( { search: { ...this.state.search, firstName:  e.target.value } } ) } /></Col>

                            <Col xs='6' sm='3'><Form.Control placeholder={ _( 'Passnummer' ) } value={ this.state.search.passNumber } onChange={ e => this.setState( { search: { ...this.state.search, passNumber: e.target.value } } ) } /></Col>

                            <Col xs='6' sm='3' className='d-grid'><Button type='submit' disabled={ ! this.searchFormSubmittable() }>{ _( 'Suchen' ) }</Button></Col>

                        </Row>

                    </fieldset>

                </Form>

                { this.state.players.map( ( e, i ) => this.renderPlayer( e, i ) ) }

            </>

        )

    }

    render() {

        return (

            <Modal show={ this.props.show } onHide={ this.props.onHide } backdrop='static' size='lg'>

                <Modal.Header closeButton>

                    <Modal.Title>{ _( 'Sonderberechtigung' ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    { this.state.selectedPlayer ?

                        ( this.state.saving ?

                            this.getStatusMessage( _( 'Antrag wird gesendet...' ), '', 'spinner', true )

                        : this.state.success ?

                            this.getStatusMessage( _( 'Antrag wurde gesendet.' ), 'text-success', 'check' )

                        : this.state.error ?

                            this.getStatusMessage( _( 'Antrag konnte nicht gesendet werden.' ), 'text-danger', 'times' )

                        :

                            this.renderRequestForm()

                        )

                    :

                        this.renderSearchForm()

                    }

                </Modal.Body>

                <Modal.Footer>

                    { ! this.state.saving && ! this.state.complete && <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button> }

                    { this.state.selectedPlayer && ! this.state.saving && ! this.state.complete && <Button onClick={ () => this.save() }>{ _( 'Antrag senden' ) }</Button> }

                    { this.state.selectedPlayer && this.state.complete && <Button onClick={ this.props.onHide } disabled={ this.state.saving }>{ _( 'Schließen' ) }</Button> }

                </Modal.Footer>

            </Modal>

        )

    }

}