import { Icon, translate as _, ajx, deepCopy, formatDate, parseJSON } from '@morawadigital/skynet-framework'
import React from 'react'
import { Button, Card, Col, Form, FormCheck, Modal, Row } from 'react-bootstrap'
import FieldSet from '../elements/FieldSet'
import Documents from '../elements/Documents'
import { deleteItem, getBooleanValue, getFullName, getStringValue, updateItem } from '../../util'
import { getFieldDefinition } from '../../fieldDefinition'
import { PERSON_NAME_CHANGE_REQUEST_STATUS_ACCEPTED, PERSON_NAME_CHANGE_REQUEST_STATUS_DRAFT, PERSON_NAME_CHANGE_REQUEST_STATUS_PENDING, PERSON_NAME_CHANGE_REQUEST_STATUS_REJECTED } from '../../util/constants'
import { datatron } from '../../util/datatron'
import { documentType } from '../../objectStores/documentType'
import { isClub } from '../../util/context'

export default class PersonNameChangeRequest extends React.Component {

    // TODO Modal PersonNameChangeRequest

    #dom

    constructor( props ) {

        super( props )

        this.state = {

            documentTypes:           null,
            error:                   false,
            fieldDefinition:         null,
            isNew:                   false,
            keepDocuments:           true,
            loadingPersons:          false,
            personNameChangeRequest: null,
            persons:                 [],
            responseStatus:          null,
            saving:                  false,
            search:                  { lastName: '', firstName: '', passNumber: '' },
            selectedPerson:          null,
            show:                    false,
            step:                    1,
            success:                 false,
            validated:               false,

        }

        this.#dom = {

            form: React.createRef(),

        }

    }

    componentDidMount() {

        this.loadFieldDefinition()

        this.loadDocumentTypes()

    }

    componentDidUpdate() {

        if ( this.props.personNameChangeRequest && ! this.state.personNameChangeRequest ) {

            this.setState( {

                error:                   false,
                isNew:                   this.props.personNameChangeRequest.isNew,
                keepDocuments:           true,
                loadingPersons:          false,
                personNameChangeRequest: this.convertPersonNameChangeRequest( this.props.personNameChangeRequest ),
                persons:                 [],
                saving:                  false,
                search:                  { lastName: '', firstName: '', passNumber: '' },
                selectedPerson:          null,
                step:                    1,
                success:                 false,
                validated:               false,

            } )

        } else if ( ! this.props.personNameChangeRequest && this.state.personNameChangeRequest ) {

            this.setState( { personNameChangeRequest: null } )

        }

    }

    canEdit() {

        if ( this.state.isNew ) {

            return true

        }

        if ( this.state.personNameChangeRequest.Status === PERSON_NAME_CHANGE_REQUEST_STATUS_PENDING && this.props.respondable ) {

            return true

        }

        return false

    }

    convertPersonNameChangeRequest( personNameChangeRequest ) {

        personNameChangeRequest = deepCopy( personNameChangeRequest )

        return personNameChangeRequest

    }

    getStatusMessage( text, className, icon, spin ) {

        const iconProps = { icon }

        if ( spin ) {

            iconProps.spin = true

        }

        return (

            <div className={ 'text-center ' + className }>

                <div className='fs-1 my-3'>

                    <Icon { ...iconProps } />

                </div>

                <p className='lead'>{ text }</p>

            </div>

        )

    }

    loadDocumentTypes() {

        datatron.get( {

            cacheFirst:     true,
            objectStore:    documentType,
            requestOptions: { options: { method: 'GET' }, url: 'api/DocumentType/Get' },
            success:        documentTypes => this.setState( { documentTypes: documentTypes.data.filter( e => e.object === 'NameChangeRequest' ) } ),

        } )

    }

    loadFieldDefinition() {

        getFieldDefinition( {

            objectName: 'NameChangeRequest',
            success:    fieldDefinition => this.setState( { fieldDefinition } ),

        } )

    }

    loadPersons( e ) {

        e.preventDefault()

        if ( ! this.searchFormSubmittable() ) {

            return

        }

        this.setState( { loadingPersons: true }, () => ajx( {

            complete: () => this.setState( { loadingPersons: false } ),
            options:  { method: 'GET' },
            success:  persons => this.setState( { persons } ),
            url:      'api/NameChangeRequest/GetPlayer',

            data:     {

                clubId:    ( isClub( this.props.context ) ? this.props.context.club.id : '' ),
                firstName: this.state.search.firstName,
                lastName:  this.state.search.lastName,
                passNr:    this.state.search.passNumber,

            },

        } ) )

    }

    respond( status ) {

        this.setState( { error: false, responseStatus: status, saving: true, success: false }, () => {

            const data = {

                documentsByPostReceived: getBooleanValue( this.state.personNameChangeRequest.DocumentsByPostReceived ),
                id:                      this.state.personNameChangeRequest.Id,
                newLastName:             getStringValue( this.state.personNameChangeRequest.NewLastName              ),
                responseNote:            getStringValue( this.state.personNameChangeRequest.ResponseNote             ),

                status,

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                error:    () => this.setState( { error: true } ),
                single:   true,
                success:  personNameChangeRequest => this.setState( { personNameChangeRequest, success: true }, () => this.props.onUpdate( personNameChangeRequest ) ),
                url:      'api/NameChangeRequest/Respond',

                data,

            } )

        } )

    }

    save( status, step ) {

        if ( this.#dom.form.current && ! this.#dom.form.current.checkValidity() ) {

            this.setState( { validated: true } )

            return

        }

        this.setState( { error: false, saving: true, success: false, validated: true }, () => {

            const data = {

                clubId:              isClub( this.props.context ) && this.props.context.club.id,
                documentsByPostSent: getBooleanValue( this.state.personNameChangeRequest.DocumentsByPostSent ),
                newLastName:         getStringValue( this.state.personNameChangeRequest.NewLastName          ),
                note:                getStringValue( this.state.personNameChangeRequest.Note                 ),
                playerId:            this.state.selectedPerson.Id,

                status,

            }

            if ( this.state.personNameChangeRequest.Id ) {

                data.id = this.state.personNameChangeRequest.Id

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                error:    () => this.setState( { error: true } ),
                single:   true,
                url:      'api/NameChangeRequest/Create',

                success: personNameChangeRequest => {

                    personNameChangeRequest = this.convertPersonNameChangeRequest( personNameChangeRequest )

                    this.setState( { personNameChangeRequest, step, success: true }, () => step === 4 && this.props.onUpdate( personNameChangeRequest ) )

                },

                data,

            } )

        } )

    }

    searchFormSubmittable() {

        return ( this.state.search.lastName || this.state.search.firstName || this.state.search.passNumber )

    }

    selectPerson( selectedPerson ) {

        this.setState( { selectedPerson, step: 2, personNameChangeRequest: { ...this.state.personNameChangeRequest, NewLastName: selectedPerson.Person.LastName } } )

    }

    send() {

        this.setState( { step: 4 }, () => this.save( PERSON_NAME_CHANGE_REQUEST_STATUS_PENDING, 4 ) )

    }

    renderBody() {

        return (

            this.state.saving ?

                this.getStatusMessage( this.state.responseStatus === PERSON_NAME_CHANGE_REQUEST_STATUS_ACCEPTED ? _( 'Antrag wird akzeptiert...' ) : _( 'Antrag wird abgelehnt...' ), '', 'spinner', true )

            : this.state.success ?

                this.getStatusMessage( this.state.responseStatus === PERSON_NAME_CHANGE_REQUEST_STATUS_ACCEPTED ? _( 'Antrag wurde akzeptiert.' ) : _( 'Antrag wurde abgelehnt.' ), 'text-success', 'check' )

            : this.state.error ?

                this.getStatusMessage( this.state.responseStatus === PERSON_NAME_CHANGE_REQUEST_STATUS_ACCEPTED ? _( 'Antrag konnte nicht akzeptiert werden.' ) : _( 'Antrag konnte nicht abgelehnt werden.' ), 'text-danger', 'times' )

            :

                <>

                    { this.renderFields() }

                    <h6 className='my-3 pt-3 border-top text-muted'>{ _( 'Dokumente' ) }</h6>

                    { this.state.personNameChangeRequest.DocumentsByPostSent ?

                        <>

                            <div>

                                <Icon icon='info-circle' /> { _( 'Unterlagen wurden postalisch eingereicht.' ) }

                            </div>

                            { this.state.personNameChangeRequest.Status === PERSON_NAME_CHANGE_REQUEST_STATUS_PENDING && this.props.respondable ?

                                <Card className='my-4'>

                                    <Card.Body className='lead'>

                                        <Form.Check
                                            checked= { this.state.personNameChangeRequest.DocumentsByPostReceived                                                                    }
                                            id=      'person-name-change-request-modal-documents-received-by-post'
                                            label=   { _( 'Unterlagen sind postalisch eingelangt.' )                                                                       }
                                            onChange={ e => this.setState( { personNameChangeRequest: { ...this.state.personNameChangeRequest, DocumentsByPostReceived: e.target.checked } } ) }
                                        />

                                    </Card.Body>

                                </Card>

                            : this.state.personNameChangeRequest.DocumentsByPostReceived &&

                                <div>

                                    <Icon icon='info-circle' /> { _( 'Unterlagen sind postalisch eingelangt.' ) }

                                </div>

                            }

                        </>

                    :

                        <>

                            { this.renderDocuments() }

                            { this.state.personNameChangeRequest.Status === PERSON_NAME_CHANGE_REQUEST_STATUS_PENDING && this.props.respondable &&

                                <FormCheck
                                    checked= { this.state.keepDocuments                                  }
                                    disabled={ this.state.saving                                         }
                                    id=      'keepDocuments'
                                    label=   { _( 'Dokumente übernehmen' )                               }
                                    onChange={ e => this.setState( { keepDocuments: e.target.checked } ) }
                                />

                            }

                        </>

                    }

                </>

        )

    }

    renderButtons() {

        return (

            <>

                { this.state.personNameChangeRequest.Status === PERSON_NAME_CHANGE_REQUEST_STATUS_PENDING && this.props.respondable ?

                    <>

                        <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button>

                        <Button onClick={ () => this.respond( PERSON_NAME_CHANGE_REQUEST_STATUS_ACCEPTED ) } disabled={ this.state.saving || ( this.state.personNameChangeRequest.DocumentsByPostSent && ! this.state.personNameChangeRequest.DocumentsByPostReceived ) }>{ _( 'Akzeptieren' ) }</Button>

                        <Button onClick={ () => this.respond( PERSON_NAME_CHANGE_REQUEST_STATUS_REJECTED ) } disabled={ this.state.saving } variant='outline-danger'>{ _( 'Ablehnen' ) }</Button>

                    </>

                :

                    <>

                        <Button onClick={ this.props.onHide }>{ _( 'Schließen' ) }</Button>

                    </>

                }

            </>

        )

    }

    renderDocuments() {

        return (

            <Documents
                disabled=     { ! this.canEdit() || this.state.saving }
                documents=    { this.state.personNameChangeRequest.PersonDocuments }
                documentTypes={ this.state.documentTypes }
                objectName=   'NameChangeRequest'
                onChange=     { e => this.setState( { personNameChangeRequest: { ...this.state.personNameChangeRequest, PersonDocuments: updateItem( this.state.personNameChangeRequest.PersonDocuments, e ) } } ) }
                onDelete=     { e => this.setState( { personNameChangeRequest: { ...this.state.personNameChangeRequest, PersonDocuments: deleteItem( this.state.personNameChangeRequest.PersonDocuments, e ) } } ) }
                requiredKey=  'requiredForNameChangeRequest'
                uploadParams= { { personRequestId: this.state.personNameChangeRequest.Id } }
            />

        )

    }

    renderFields() {

        return (

            <Form onSubmit={ e => e.preventDefault() } noValidate validated={ this.state.validated } ref={ this.#dom.form }>

                <FieldSet
                    disabled=       { ! this.canEdit() || this.state.saving }
                    fieldDefinition={ this.state.fieldDefinition }
                    onChange=       { ( e, f ) => this.setState( { personNameChangeRequest: { ...this.state.personNameChangeRequest, [ e ]: f } } ) }
                    values=         { this.state.personNameChangeRequest }
                />

            </Form>

        )

    }

    renderPerson( person, i ) {

        return (

            <Card
                className='mb-1 selectable'
                key={ i }
                onClick={ () => this.selectPerson( person ) }
            >

                <Card.Body className='p-2'>

                    <Row className='gx-0'>

                        <Col xs='3' lg='3'>{ person.PassNr }</Col>

                        <Col>{ getFullName( person.Person, true ) }</Col>

                        <Col xs='auto'>{ person.Person && person.Person.Birthdate ? formatDate( person.Person.Birthdate ) : '' }</Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

    renderRequestBody() {

        return (

            this.state.step === 1 ?

                this.renderSearchForm()

            : this.state.step === 2 ?

                this.renderFields()

            : this.state.step === 3 ?

                <>

                    { ! this.state.personNameChangeRequest.DocumentsByPostSent &&

                        <>

                            { this.renderDocuments() }

                            <div className='mt-3 text-center text-muted'>{ _( 'oder' ) }</div>

                        </>

                    }

                    <Card className='my-4'>

                        <Card.Body className='lead'>

                            <Form.Check
                                checked= { this.state.personNameChangeRequest.DocumentsByPostSent                                                                    }
                                id=      'person-name-change-request-modal-documents-sent-by-post'
                                label=   { _( 'Unterlagen werden postalisch eingereicht.' )                                                                }
                                onChange={ e => this.setState( { personNameChangeRequest: { ...this.state.personNameChangeRequest, DocumentsByPostSent: e.target.checked } } ) }
                            />

                        </Card.Body>

                    </Card>

                </>

            :

                ( this.state.saving ?

                    this.getStatusMessage( _( 'Antrag wird gesendet...' ), '', 'spinner', true )

                : this.state.success ?

                    this.getStatusMessage( _( 'Antrag wurde gesendet.' ), 'text-success', 'check' )

                : this.state.error ?

                    this.getStatusMessage( _( 'Antrag konnte nicht gesendet werden.' ), 'text-danger', 'times' )

                :

                    ''

                )

        )

    }

    renderRequestButtons() {

        const missingDocuments = []

        this.state.documentTypes && this.state.documentTypes.forEach( documentType => parseJSON( documentType.otherConfig, {} ).requiredForNameChangeRequest && ! this.state.personNameChangeRequest.PersonDocuments.find( e => e.DocumentType.Id === documentType.id ) && missingDocuments.push( documentType ) )

        return (

            <>

                { this.state.step < 4 && <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button> }

                { this.state.step === 2 ?

                    <>

                        <Button variant='secondary' onClick={ () => this.setState( { selectedPerson: null, step: 1 } ) }>{ _( 'Zurück' ) }</Button>

                        <Button onClick={ () => this.save( PERSON_NAME_CHANGE_REQUEST_STATUS_DRAFT, 3 ) } disabled={ this.state.saving }>{ _( 'Weiter' ) }</Button>

                    </>

                : this.state.step === 3 ?

                    <>

                        <Button variant='secondary' onClick={ () => this.setState( { step: 2 } ) }>{ _( 'Zurück' ) }</Button>

                        <Button onClick={ () => this.send() } disabled={ missingDocuments.length > 0 && ! this.state.personNameChangeRequest.DocumentsByPostSent }>{ _( 'Antrag senden' ) }</Button>

                    </>

                : this.state.step === 4 ?

                    <Button onClick={ this.props.onHide } disabled={ this.state.saving }>{ _( 'Schließen' ) }</Button>

                :

                    null

                }

            </>

        )

    }

    renderSearchForm() {

        return (

            <>

                <Form onSubmit={ e => this.loadPersons( e ) } className='mb-3'>

                    <fieldset disabled={ this.state.loadingPersons }>

                        <Row className='g-1'>

                            <Col xs='6' sm='3'><Form.Control placeholder={ _( 'Nachname'   ) } value={ this.state.search.lastName   } onChange={ e => this.setState( { search: { ...this.state.search, lastName:   e.target.value } } ) } /></Col>

                            <Col xs='6' sm='3'><Form.Control placeholder={ _( 'Vorname'    ) } value={ this.state.search.firstName  } onChange={ e => this.setState( { search: { ...this.state.search, firstName:  e.target.value } } ) } /></Col>

                            <Col xs='6' sm='3'><Form.Control placeholder={ _( 'Passnummer' ) } value={ this.state.search.passNumber } onChange={ e => this.setState( { search: { ...this.state.search, passNumber: e.target.value } } ) } /></Col>

                            <Col xs='6' sm='3' className='d-grid'><Button type='submit' disabled={ ! this.searchFormSubmittable() }>{ _( 'Suchen' ) }</Button></Col>

                        </Row>

                    </fieldset>

                </Form>

                { this.state.persons.map( ( e, i ) => this.renderPerson( e, i ) ) }

            </>

        )

    }

    render() {

        return (

            <Modal show={ this.state.personNameChangeRequest !== null } onHide={ this.props.onHide } backdrop='static' size='lg'>

                { this.state.personNameChangeRequest &&

                    <>

                        <Modal.Header closeButton>

                            <Modal.Title>{ _( 'Namensänderung' ) }</Modal.Title>

                        </Modal.Header>

                        <Modal.Body>

                            { this.state.isNew ? this.renderRequestBody() : this.renderBody() }

                        </Modal.Body>

                        <Modal.Footer>

                            { this.state.isNew ? this.renderRequestButtons() : this.renderButtons() }

                        </Modal.Footer>

                    </>

                }

            </Modal>

        )

    }

}