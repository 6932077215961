import React from 'react'
import { Card, Form, Nav, Tab } from 'react-bootstrap'
import { Prompt, withRouter } from 'react-router-dom'
import { Icon, ajx, ajxs, translate as _, parseJSON } from '@morawadigital/skynet-framework'
import { getFieldDefinition } from '../../fieldDefinition'
import Floaties from '../controls/Floaties'
import { createOptions, deleteItem, getDateValue, getFullName, getGenders, getSelectValue, getSides, getStringValue, isFemale, updateItem } from '../../util'
import FieldSet from '../elements/FieldSet'
import Documents from '../elements/Documents'
// import Coach from '../elements/Coach'
import Player from '../elements/Player'
import { GENDER_FEMALE } from '../../util/constants'
import TabButton from '../elements/TabButton'
import { datatron } from '../../util/datatron'
import { documentType } from '../../objectStores/documentType'

class Person extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            activeTab:         'basicData',
            coach:             this.createCoach(),
            documentTypes:     null,
            fieldDefinition:   null,
            documents:         [],
            hasUnsavedChanges: false,
            nations:           null,
            person:            this.createPerson(),
            player:            this.createPlayer(),
            saving:            false,
            validated:         false,
            valueOptions:      { genders: getGenders() },

        }

        this.checkUnsavedChanges = this.checkUnsavedChanges.bind( this )

    }

    componentDidMount() {

        window.addEventListener( 'beforeunload', this.checkUnsavedChanges )

        this.loadFieldDefinition()

        this.loadDocumentTypes()

        this.load()

    }

    componentWillUnmount() {

        window.removeEventListener( 'beforeunload', this.checkUnsavedChanges )

    }

    checkUnsavedChanges( e ) {

        if ( this.state.hasUnsavedChanges ) {

            e.preventDefault()

            return e.returnValue = _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.' )

        }

    }

    createCoach( coach ) {

        return {

            CoachLicenses:                [],
            CoachNumber:                  '',
            CoachTrainings:               [],
            CodeOfHonorDoc:               false,
            CriminalRecordCertificateDoc: false,
            DOSBNumber:                   '',
            FirstAidProofDoc:             false,
            Id:                           0,
            LastPrintDate:                null,
            LastSendDate:                 null,
            LimsId:                       null,
            Note:                         '',
            PersonId:                     0,

            ...( coach || {} ),

        }

    }

    createPerson( person ) {

        const createdPerson = {

            Address:      null,
            Birthdate:    null,
            BirthName:    '',
            Birthplace:   '',
            Coach:        null,
            FirstName:    '',
            Gender:       null,
            Id:           0,
            Image:        '',
            LastName:     '',
            Nation:       null,
            PhoneNumbers: [],
            Player:       null,
            SecondNation: null,

            ...( person || {} ),

        }

        if ( person ) {

            createdPerson.Gender       = person.Gender !== null ? getGenders().find( e => e.value === person.Gender )                : null
            createdPerson.Nation       = person.Nation          ? { label: person.Nation.Name,       value: person.Nation.Id       } : null
            createdPerson.PhoneNumbers = person.PhoneNumbers    ? parseJSON( person.PhoneNumbers, [] )                               : []
            createdPerson.SecondNation = person.SecondNation    ? { label: person.SecondNation.Name, value: person.SecondNation.Id } : null

        }

        return createdPerson

    }

    createPlayer( player ) {

        const createdPlayer = {

            Club:                           null,
            Height:                         null,
            Id:                             0,
            InformationForAction:           '',
            MedicalRecords:                 '',
            NeedsTransferCard:              false,
            Note:                           '',
            PassNr:                         '',
            PersonId:                       0,
            PlayerContingent:               null,
            PlayerLicenseRequests:          [],
            PlayerLicenses:                 [],
            PlayerStatus:                   null,
            ShotCatchHand:                  null,
            TransferCards:                  [],
            WaiverOfMedicalConfidentiality: false,
            Weight:                         null,

            ...( player || {} ),

        }

        if ( player ) {

            createdPlayer.ShotCatchHand    = player.ShotCatchHand !== null ? getSides().find( e => e.value === player.ShotCatchHand )                   : null
            createdPlayer.Club             = player.Club                   ? { label: player.Club.Name,             value: player.Club.Id             } : null
            createdPlayer.PlayerContingent = player.PlayerContingent       ? { label: player.PlayerContingent.Name, value: player.PlayerContingent.Id } : null
            createdPlayer.PlayerStatus     = player.PlayerStatus           ? { label: player.PlayerStatus.Name,     value: player.PlayerStatus.Id     } : null

        }

        return createdPlayer

    }

    delete() {

        // TODO Delete person
        alert( 'TODO Delete person' )

    }

    deleteItem( item, objectName, keyName ) {

        const items = [ ...this.state[ objectName ][ keyName ] ]
        const index = items.findIndex( e => e.Id === item.Id )

        if ( index !== -1 ) {

            items.splice( index, 1 )

        }

        this.setState( { [ objectName ]: { ...this.state[ objectName ], [ keyName ]: items } } )

    }

    getPersonId() {

        return this.state.person.Id || this.props.match.params.id || 0

    }

    load() {

        const items = [

            { options: { method: 'GET' }, success: e => this.setState( { valueOptions: { ...this.state.valueOptions, nations: createOptions( e ) } } ), url: 'api/Nation/Get' },

        ]

        const personId = this.getPersonId()

        if ( personId !== _( 'neu' ) ) {

            items.push( {

                data:    { id: personId },
                single:  true,
                url:     'api/Person/Get',

                success: e => this.setState( {

                    person:    this.createPerson( e ),
                    coach:     this.createCoach(  { PersonId: personId, ...( e.Coach  || {} ) } ),
                    player:    this.createPlayer( { PersonId: personId, ...( e.Player || {} ) } ),
                    documents: e.PersonDocuments,

                } ),

            } )

        }

        ajxs( { toggleLoading: true }, items )

    }

    loadDocumentTypes() {

        datatron.get( {

            cacheFirst:     true,
            objectStore:    documentType,
            requestOptions: { options: { method: 'GET' }, url: 'api/DocumentType/Get' },
            success:        documentTypes => this.setState( { documentTypes: documentTypes.data.filter( e => e.object === 'Person' ) } ),

        } )

    }

    loadFieldDefinition() {

        getFieldDefinition( {

            objectName: 'Person',
            success:    fieldDefinition => this.setState( { fieldDefinition } ),

        } )

    }

    save( e ) {

        e.preventDefault()

        if ( ! e.currentTarget.checkValidity() ) {

            this.setState( { validated: true } )

            return

        }

        this.setState( { saving: true, validated: true }, () => {

            const data = {

                address:        this.state.person.Address ? JSON.stringify( this.state.person.Address ) : '',
                birthdate:      getDateValue( this.state.person.Birthdate      ),
                birthName:      getStringValue( this.state.person.BirthName    ),
                birthplace:     getStringValue( this.state.person.Birthplace   ),
                firstName:      getStringValue( this.state.person.FirstName    ),
                gender:         getSelectValue( this.state.person.Gender       ),
                id:             this.state.person.Id,
                image:          getStringValue( this.state.person.Image        ),
                lastName:       getStringValue( this.state.person.LastName     ),
                nationId:       getSelectValue( this.state.person.Nation       ),
                phoneNumbers:   this.state.person.PhoneNumbers ? JSON.stringify( this.state.person.PhoneNumbers ) : '',
                secondNationId: getSelectValue( this.state.person.SecondNation ),

            }

            const success = e => {

                const personId = this.getPersonId()
                const state    = { hasUnsavedChanges: false }

                if ( ! this.state.person.Id ) {

                    state.person = { ...this.state.person, Id:       e.Id }
                    state.coach  = { ...this.state.coach,  PersonId: e.Id }
                    state.player = { ...this.state.player, PersonId: e.Id }

                }

                this.setState( state, () => personId === _( 'neu' ) && this.props.history.push( _( '/person/' ) + e.Id ) )

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                single:   true,
                url:      'api/Person/AddOrEdit',

                data, success,

            } )

        } )

    }

    updateItem( item, objectName, keyName ) {

        const items = [ ...this.state[ objectName ][ keyName ] ]
        const index = items.findIndex( e => e.Id === item.Id )

        if ( index === -1 ) {

            items.push( item )

        } else {

            items[ index ] = item

        }

        this.setState( { [ objectName ]: { ...this.state[ objectName ], [ keyName ]: items } } )

    }

    render() {

        const formDisabled                            = this.props.loading || this.state.saving
        const labelPlayer                             = this.state.person.Gender !== null && this.state.person.Gender.value === GENDER_FEMALE ? _( 'Spielerin' ) : _( 'Spieler' )
        const missingDocumentsForPlayerLicense        = []
        const missingDocumentsForPlayerLicenseRequest = []

        this.state.documentTypes && this.state.documentTypes.forEach( documentType => {

            const otherConfig = parseJSON( documentType.otherConfig, {} )

            otherConfig.requiredForPlayerLicense        && ! this.state.documents.find( e => e.DocumentType.Id === documentType.id ) && missingDocumentsForPlayerLicense.push(        documentType )
            otherConfig.requiredForPlayerLicenseRequest && ! this.state.documents.find( e => e.DocumentType.Id === documentType.id ) && missingDocumentsForPlayerLicenseRequest.push( documentType )

        } )

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='user' className='subheader-icon' /> { _( 'Person' ) } { this.state.person && <span className='fw-300'>{ getFullName( this.state.person ) }</span> }

                    </h1>

                </div>

                <Card>

                    <fieldset disabled={ formDisabled }>

                        <Tab.Container defaultActiveKey='basicData' onSelect={ activeTab => this.setState( { activeTab } ) } activeKey={ this.state.activeTab }>

                            <Card.Header>

                                <Nav variant='pills'>

                                    <TabButton eventKey='basicData' label={ _( 'Stammdaten' ) }                                     />

                                    <TabButton eventKey='documents' label={ _( 'Dateien'    ) } disabled={ ! this.state.person.Id } />

                                    <TabButton eventKey='player'    label={ labelPlayer       } disabled={ ! this.state.person.Id } />

                                    {/* // TODO Enable via customer setting */}
                                    {/* <TabButton eventKey='coach'     label={ _( 'Coach'      ) } disabled={ ! this.state.person.Id } /> */}

                                </Nav>

                            </Card.Header>

                            <Card.Body>

                                <Tab.Content>

                                    <Tab.Pane eventKey='basicData'>

                                        <Form onSubmit={ e => this.save( e ) } noValidate validated={ this.state.validated }>

                                            <FieldSet
                                                disabled={ formDisabled }
                                                fieldDefinition={ this.state.fieldDefinition }
                                                onChange={ ( e, f ) => this.setState( { person: { ...this.state.person, [ e ]: f }, hasUnsavedChanges: true } ) }
                                                valueOptions={ this.state.valueOptions }
                                                values={ this.state.person }
                                            />

                                            <Floaties buttons={ [

                                                { icon: 'save',  label: _( 'Speichern' ), disabled: formDisabled, type: 'submit' },
                                                // { icon: 'trash', label: _( 'Löschen'   ), disabled: formDisabled || ! this.state.person.Id, onClick: () => this.delete(), variant: 'danger', outline: true, position: 'end' },

                                            ] } />

                                        </Form>

                                    </Tab.Pane>

                                    <Tab.Pane eventKey='documents'>

                                        <Documents
                                            disabled=     { this.props.loading }
                                            documents=    { this.state.documents }
                                            documentTypes={ this.state.documentTypes }
                                            objectName=   'Person'
                                            onChange=     { e => this.setState( { documents: updateItem( this.state.documents, e ) } ) }
                                            onDelete=     { e => this.setState( { documents: deleteItem( this.state.documents, e ) } ) }
                                            uploadParams= { { personId: this.getPersonId() } }
                                        />

                                    </Tab.Pane>

                                    <Tab.Pane eventKey='player'>

                                        <Player
                                            isFemale=                               { isFemale( this.state.person )                                               }
                                            loading=                                { this.props.loading                                                          }
                                            missingDocumentsForPlayerLicense=       { missingDocumentsForPlayerLicense                                            }
                                            missingDocumentsForPlayerLicenseRequest={ missingDocumentsForPlayerLicenseRequest                                     }
                                            onChange=                               { ( e, f ) => this.setState( { player: { ...this.state.player, [ e ]: f } } ) }
                                            onAddPlayerLicenseRequest=              { e => this.updateItem( e, 'player', 'PlayerLicenseRequests' )                }
                                            onDeletePlayerLicense=                  { e => this.deleteItem( e, 'player', 'PlayerLicenses' )                       }
                                            onDeleteTransferCard=                   { e => this.deleteItem( e, 'player', 'TransferCards' )                        }
                                            onUpdatePlayerLicense=                  { e => this.updateItem( e, 'player', 'PlayerLicenses' )                       }
                                            onUpdateTransferCard=                   { e => this.updateItem( e, 'player', 'TransferCards' )                        }
                                            player=                                 { this.state.player                                                           }
                                        />

                                    </Tab.Pane>

                                    {/* // TODO Enable via customer config */}
                                    {/* <Tab.Pane eventKey='coach'>

                                        <Coach
                                            coach={ this.state.coach }
                                            loading={ this.props.loading }
                                            onChange={ ( e, f ) => this.setState( { coach: { ...this.state.coach, [ e ]: f } } ) }
                                            onDeleteCoachLicense={ e => this.deleteItem( e, 'coach', 'CoachLicenses' ) }
                                            onDeleteCoachTraining={ e => this.deleteItem( e, 'coach', 'CoachTrainings' ) }
                                            onUpdateCoachLicense={ e => this.updateItem( e, 'coach', 'CoachLicenses' ) }
                                            onUpdateCoachTraining={ e => this.updateItem( e, 'coach', 'CoachTrainings' ) }
                                        />

                                    </Tab.Pane> */}

                                </Tab.Content>

                            </Card.Body>

                        </Tab.Container>

                    </fieldset>

                </Card>

                <Prompt message={ _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.' ) } when={ this.state.hasUnsavedChanges } />

            </>

        )

    }

}

export default withRouter( Person )