import { translate as _ } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_ADDRESS, FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_TEXTFIELD, FIELD_TYPE_TOGGLE } from '../util/constants'

export const Federation = {

    'Address':          { __label: _( 'Adresse'                ), __type: FIELD_TYPE_ADDRESS                                   },
    'DistrictCourt':    { __label: _( 'District Court'         ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'Homepage':         { __label: _( 'Webseite'               ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'Icon':             { __label: _( 'Icon'                   ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'MainFederation':   { __label: _( 'Hauptverband'           ), __type: FIELD_TYPE_TOGGLE                                    },
    'Name':             { __label: _( 'Name'                   ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'ParentFederation': { __label: _( 'Übergeordneter Verband' ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'federations' },
    'RegistryNumber':   { __label: _( 'Registry Number'        ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'Shortname':        { __label: _( 'Kurzbezeichnung'        ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'TaxId':            { __label: _( 'UID'                    ), __type: FIELD_TYPE_TEXTFIELD                                 },
    'TaxNumber':        { __label: _( 'Tax Number'             ), __type: FIELD_TYPE_TEXTFIELD                                 },

}